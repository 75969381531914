import Header from './components/header/Header';


function App() {
  return (
    <>
      
      <Header />
    </>
  );
}

export default App;
