import React from 'react'
import CTA from './CTA';
import './header.css';
import ASPIRANT from '../../assets/bg.jpeg';
import { init } from 'ityped';
import { useEffect, useRef } from 'react';
import { FaPrayingHands } from 'react-icons/fa';

const Header = () => {
  const textRef = useRef();

  useEffect(() => {
    init(textRef.current, {
      showCursor: true,
      typeSpeed: 100,
      backDelay: 1500,
      backSpeed: 20,
      strings: ['Our website is currently under maintenance, will be back soon.', 'Meanwhile, DOWNLOAD the Iboro Otu Governorship Campaign Manifesto below.', 'To support and join the campaign, please call: +234 812 076 5141, +234 703 826 4736']
    });
  }, []);
  return (
    <header>
      <div className='up__section'>
        <h3><FaPrayingHands /> Notice: <span ref={textRef}></span></h3>
      </div>
      <div className="container header__container">
        <div className="df1">
          <div className='contents'>
            <div>
              {/* <h3>Welcome</h3> */}
              <h1 className='uppercase'>Iboro Otu</h1>
              <h5 className='text-light pt-3 uppercase'>Governorship Support Team 2023</h5>
              <CTA />
              {/* <p className='fontf phone'><strong>For enquiries pls call:</strong> +234 812 076 5141, +234 703 826 4736</p> */}
            </div>
          </div>

          <div className='aspirant'>
            <img src={ASPIRANT} alt="Akwa Ibom Governship Aspirant" />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header